
import { Options, Vue } from 'vue-class-component'
import matchSchedule from '@/components/Frontend/MatchSchedule/index.vue'

@Options({
  components: {
    matchSchedule
  }
})

export default class MatchScheduleView extends Vue {}
